import { render, staticRenderFns } from "./Insights.vue?vue&type=template&id=a095b592&scoped=true&"
import script from "./Insights.vue?vue&type=script&lang=js&"
export * from "./Insights.vue?vue&type=script&lang=js&"
import style0 from "./Insights.vue?vue&type=style&index=0&id=a095b592&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a095b592",
  null
  
)

export default component.exports